import React from 'react';
import PropTypes from 'prop-types';
import { localeCodes, localeManager } from '@pcid/locale-utils';

import LoadingAnimation from '../loading-animation';

const LoadingPage = ({ initLanguage }) => {
	const isLocaleEnglish = initLanguage
		? initLanguage === localeCodes.EN.slice(0, 2)
		: localeManager.getLocale() === localeCodes.EN;
	const loadingText = isLocaleEnglish ? 'Loading' : 'Chargement';
	return (
		<div className="loading-page">
			<span>{loadingText}</span>
			<LoadingAnimation />
		</div>
	);
};

LoadingPage.propTypes = {
	initLanguage: PropTypes.string,
};

export default LoadingPage;
